<div class="">
  <app-header></app-header>

  <div class="page-body">
    <div [ngClass]="{'p-0' : currentRoute === '/marketplace' || currentRoute === '/' || currentRoute.match('products') !== null }">
      <div style="padding-top: 70px;">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
