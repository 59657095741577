import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';
import { UseraccountService } from 'src/app/account/auth/useraccount.service';
import { WindowService } from '../services/window.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor( private _win: WindowService ,private useraccountService: UseraccountService,private cookieService: SsrCookieService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //Add access token in api headers
        const accessToken = this.cookieService.get('userAccessToken');
        const isRestrict = this.cookieService.get('isRestrict');
        if(accessToken && isRestrict !== 'true' ){
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }
        else if(this.useraccountService.verifyOtpToken){
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.useraccountService.verifyOtpToken}`
                }
            });
        }
        else{
            request = request.clone({
                setHeaders: {
                    Authorization: 'guest-mode'
                }
            });
        }
        return next.handle(request);
    }
}
