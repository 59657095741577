<div class="container">
<div class="row">
    <!-- <div class="col-md-12">
        <img loading="lazy" class="Logo" src="assets\images\dayim-logo.svg" alt="image" >
    </div> -->
    <div class="text-center col-md-12">
        <h1>Great! Everything is ready</h1>
    </div>
    <div class="text-center col-md-12">
        <img loading="lazy" class="Logo" src="assets\images\intropageimage.png" alt="image" >
    </div>
    <div class="text-center col-md-12">
        <h5>Your account has been verified and will recieve a confirmation email soon.</h5>
    </div>
    <div class="text-center col-md-12">
        <button type="button" class="btn btn-warning">Let’s get started</button>
    </div>

</div>
</div>