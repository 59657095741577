<section>
    <div class="container">
        <div class="row">
            <div class="text-center col-md-12">
                <h1>Email Sent</h1>
            </div>
            <div class="text-center col-md-12">
                <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque fringilla blandit ipsum sed blandit.</h5>
            </div>
            <div class="text-center col-md-12">
                <img loading="lazy" class="Logo" src="assets\images\emailsent.png" alt="image" >
            </div>
        </div>
    </div>
</section>