import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { WindowService } from './window.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'ar'];

  constructor(private _win: WindowService, public translate: TranslateService, private cookieService: SsrCookieService) {
    // let browserLang = 'en' ;
    // this.translate.addLangs(this.languages);
    // if (this.cookieService.check('lang')) {
    //   browserLang = this.cookieService.get('lang');
    //   if(browserLang == 'ar'){
    //     this._win.getDocument().getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    //     this._win.getDocument().getElementsByTagName("html")[0].setAttribute("lang", "ar");
    //   }
    // }
    // else {
    //   this.setLanguage('en');
    //   browserLang = translate.getBrowserLang();
    //   this._win.getDocument().getElementsByTagName("html")[0].removeAttribute("dir")
    //   this._win.getDocument().getElementsByTagName("html")[0].setAttribute("lang", "en");
    // }
    // translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    this.cookieService.set('lang', lang, { path: '/' });
    //console.log('language service update')
    if(lang !== 'en'){
      this._win.getDocument().getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      this._win.getDocument().getElementsByTagName("html")[0].setAttribute("lang", "ar");
    }else{
      this._win.getDocument().getElementsByTagName("html")[0].removeAttribute("dir")
      this._win.getDocument().getElementsByTagName("html")[0].setAttribute("lang", "en");
    }
  }

}
