import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UseraccountService {
  verifyOtpToken = '';
  email = '';
  mobile = '';
  userType = '';
  userid = '';
  constructor() { }
 
}
