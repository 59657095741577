import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { filter } from 'rxjs/operators';
import { WindowService } from 'src/app/core/services/window.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerPage: boolean = false;

  @Input() isFooter;
  routerLink: any = '';
  userData: any;


  constructor(private _win: WindowService, private translate: TranslateService,
    private router: Router,private route: ActivatedRoute,private cookieService: SsrCookieService
  ) {
    this.routerLink = this.router.url.split("/")[1];
   }

  // set the currenr year
  year: number = new Date().getFullYear();
  currentSection = 'home';

  ngOnInit() {
    // const urlSegments = this.router.url.split("/");
    // console.log("URL Segments:", urlSegments);
    // if (urlSegments[1] === "success-contracts") {
    //   this.footerPage = true;
    // } else if (urlSegments[1] !== "success-contracts") {
    //   this.footerPage = false;
    // } 

    const routeSub = this.route.url

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log('Router URL changed:', event.url);
      this.routerLink = event.url.split("/")[1];
      console.log('Current routerLink:', this.routerLink);
    });

    if(this.cookieService.get('currentUser') !== ''){
      this.userData = JSON.parse(this.cookieService.get('currentUser'));
    }
    console.log(this.userData);
    
  }


  ngOnDestroy(): void {
  }
  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = this._win.getDocument().getElementById('navbar');
    if (this._win.getDocument().body.scrollTop >= 50 || this._win.getDocument().documentElement.scrollTop >= 50) {
      navbar.classList.add('nav-sticky')
    } else {
      navbar.classList.remove('nav-sticky')
    }
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    this._win.getDocument().getElementById('topnav-menu-content').classList.toggle('show');
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

}
