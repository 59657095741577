import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  isBrowser: boolean;

  constructor(@Inject(DOCUMENT) private _doc: Document, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }


  getWindow(): Window | null {
    return this._doc.defaultView;
  }
  getDocument(): Document | null {
    return this._doc;
  }
  getLocalStorage(key){
    if(this.isBrowser){
      return this._doc.defaultView.localStorage.getItem(key);
    }
    return '';
  }
  setLocalStorage(key, value): void | null {
    if(this.isBrowser){
      return this._doc.defaultView.localStorage.setItem(key , value);
    }
    return null;
  }
  removeLocalStorage(key): void | null {
    if(this.isBrowser){
      return this._doc.defaultView.localStorage.removeItem(key);
    }
    return null;
  }
}
