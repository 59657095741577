// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // apiUrl: 'http://localhost:3000',
  // apiUrl: 'https://bad-wasp-65.telebit.io',
  // appURL: 'http://localhost:4202',

  apiUrl: 'https://dev-api.dayimrentalshare.com',
  appURL: 'https://dev-user.dayimrentalshare.com',

  apiVersion: 'v1',
  firebase: {
    apiKey: "AIzaSyA8uQb7ZdZXZkFdsVGqhT5dryLLrI8kECI",
    serverKey: "AAAAKb-NaC8:APA91bEsJSrCf3ej96GU6dxsxtnAuh6dVioqsiDTvitRie_Jor-D_ALbS6JfkJa0aHPPOPe0rjwudyRXHafheeKVdeJNz7HlcJnfcHXY4Dfyw0wDtZ9S1zG7BRhel_ebuehW13is9Kme",
    WebNotificationKey: "BHXlN4EmDy1xoSDCE-lfTZaYVvRiJhYX0QEElzvsnEOoUzWRZtvF9kF5vZeFuhS5VVsJNRPX4asFmeQcH9XjejY",
    authDomain: "rentalshare-demo.firebaseapp.com",
    projectId: "rentalshare-demo",
    storageBucket: "rentalshare-demo.appspot.com",
    messagingSenderId: "179307374639",
    appId: "1:179307374639:web:d76813af6bb5f0f0e7feda",
    measurementId: "G-ZTXBGN8JDH"
  },
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
