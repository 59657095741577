import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Inject, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Request } from 'express';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TranslateCacheModule, TranslateCacheService, TranslateCacheSettings } from 'ngx-translate-cache';
import { translateLoaderFactory } from './translate-loaders';
import { environment } from 'src/environments/environment';
import { WindowService } from '../core/services/window.service';

@NgModule({
  imports: [
    BrowserTransferStateModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, TransferState, PLATFORM_ID]
      }
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: translateCacheFactory,
        deps: [TranslateService, TranslateCacheSettings]
      },
      cacheMechanism: 'Cookie'
    })
  ],
  exports: [TranslateModule]
})

export class I18nModule {
    public languages: string[] = ['en', 'ar'];
  
    constructor(private _win: WindowService, public translate: TranslateService, private cookieService: SsrCookieService, translateCacheService: TranslateCacheService,
    @Optional() @Inject(REQUEST) private req: Request,
    @Inject(PLATFORM_ID) private platform: any) {
      if (isPlatformBrowser(this.platform)) {
        translateCacheService.init();
      }
      
      this.translate.addLangs(this.languages);

      const browserLang = isPlatformBrowser(this.platform)
      ? translateCacheService.getCachedLanguage() || translate.getBrowserLang() || 'en'
      : this.getLangFromServerSideCookie() || 'en';
      this.setLanguage(browserLang);
    }

    getLangFromServerSideCookie() {
      if (this.req) {
        return this.req.cookies.lang;
      }
    }

    public setLanguage(lang) {
      this.translate.use(lang);
      this.cookieService.set('lang', lang, { path: '/' });
      //console.log('language service update')
      if(lang !== 'en'){
        this._win.getDocument().getElementsByTagName("html")[0].setAttribute("dir", "rtl");
        this._win.getDocument().getElementsByTagName("html")[0].setAttribute("lang", "ar");
      }else{
        this._win.getDocument().getElementsByTagName("html")[0].removeAttribute("dir")
        this._win.getDocument().getElementsByTagName("html")[0].setAttribute("lang", "en");
      }
    }
  
}

export function httpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function translateCacheFactory(translateService: TranslateService, translateCacheSettings: TranslateCacheSettings) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}