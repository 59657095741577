import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WindowService } from 'src/app/core/services/window.service';
import { TOPBAR } from "../layouts.model";

@Component({
  selector: 'app-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss']
})

/**
 * Horizontal-layout component
 */
export class HorizontalComponent implements OnInit, AfterViewInit {

  isFooter : boolean = false;

  topbar: string;
  currentRoute: string;
  constructor(private _win: WindowService,private router: Router) { 
     this.currentRoute = this.router.url
    // detect router changes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd){
        this.currentRoute = event.url
      }
    });
  }


  ngOnInit() {

 
    this.topbar = TOPBAR;
    this._win.getDocument().body.setAttribute('data-layout', 'horizontal');
    this._win.getDocument().body.removeAttribute('data-sidebar');
    this._win.getDocument().body.removeAttribute('data-layout-size');
    this._win.getDocument().body.removeAttribute('data-keep-enlarged');
    this._win.getDocument().body.removeAttribute('data-sidebar-small');    
    // this._win.getDocument().body.setAttribute('data-layout-mode', 'dark');
  }

  ngAfterViewInit() {
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    this._win.getDocument().body.classList.toggle('right-bar-enabled');
  }

  changeTopbar(topbar: string) {
    switch (topbar) {
      case "light":
        this._win.getDocument().body.setAttribute("data-topbar", "light");
        break;
      case "dark":
        this._win.getDocument().body.setAttribute("data-topbar", "dark");
        break;
      case "colored":
        this._win.getDocument().body.setAttribute("data-topbar", "colored");
        break;
      default:
        this._win.getDocument().body.setAttribute("data-topbar", "dark");
        break;
    }
  }

}
