import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { environment } from 'src/environments/environment';
import { DataStateService } from './data.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl: string = environment.apiUrl;
  apiVersion: string = environment.apiVersion;
  constructor(private http: HttpClient, private cookieService: SsrCookieService, private dataService: DataStateService) { }

  get(url: string, key?) {

    //Language Selection
    const httpOptions = {
      headers: new HttpHeaders({
        'accept-language': this.cookieService.get('lang')
      })
    }
    return this.dataService.checkAndGetData(
      key,
      this.http.get(`${this.baseUrl}/${this.apiVersion}${url}`, httpOptions),
      []
    )
  }

  post(url: string, data?, header?) {
    return this.http.post(`${this.baseUrl}/${this.apiVersion}${url}`, data, header);
  }

  delete(url: string) {
    return this.http.delete(`${this.baseUrl}/${this.apiVersion}${url}`);
  }

  put(url: string, data?) {
    return this.http.put(`${this.baseUrl}/${this.apiVersion}${url}`, data);
  }

  patch(url: string, data?) {
    return this.http.patch(`${this.baseUrl}/${this.apiVersion}${url}`, data);
  }
}
