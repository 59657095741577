import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { WindowService } from 'src/app/core/services/window.service';
import { SIDEBAR_TYPE } from "../layouts.model";

@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})

/**
 * Vertical component
 */
export class VerticalComponent implements OnInit, AfterViewInit {

  isCondensed = true;
  sidebartype: string;
  @ViewChild('navbarBrand') navbarBrand:ElementRef;
  currentRoute: any;

  constructor(private _win: WindowService,private router: Router) {
    this.currentRoute = this.router.url
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd &&this._win.getWindow().screen.width <= 993 ) {
        this._win.getDocument().body.classList.remove('sidebar-enable');
        this._win.getDocument().body.classList.remove('vertical-collpsed');
      }
    });

    // detect router changes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd){
        this.currentRoute = event.url
      }
    });

  }

  ngOnInit() {
    this.sidebartype = SIDEBAR_TYPE;
    this.changeSidebar(this.sidebartype);
    this._win.getDocument().body.setAttribute('data-layout', '');
    // //console.log(this._win.getWindow())
    if (this._win.getWindow().innerWidth >= 993) {
      this._win.getDocument().body.classList.toggle('sidebar-enable');
      this._win.getDocument().body.classList.toggle('vertical-collpsed');
    }

  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }

  ngAfterViewInit() {
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    this._win.getDocument().body.classList.toggle('right-bar-enabled');
  }

  changeSidebar(value) {
    switch (value) {
      case "light":
        this._win.getDocument().body.setAttribute('data-sidebar', 'light');
        this._win.getDocument().body.setAttribute('data-topbar', 'dark');
        this._win.getDocument().body.removeAttribute('data-sidebar-size');
        this._win.getDocument().body.removeAttribute('data-layout-size');
        this._win.getDocument().body.removeAttribute('data-keep-enlarged');
        this._win.getDocument().body.classList.remove('vertical-collpsed');
        this._win.getDocument().body.removeAttribute('data-layout-scrollable');
        break;
      case "compact":
        this._win.getDocument().body.setAttribute('data-sidebar-size', 'small');
        this._win.getDocument().body.setAttribute('data-sidebar', 'dark');
        this._win.getDocument().body.removeAttribute('data-topbar');
        this._win.getDocument().body.removeAttribute('data-layout-size');
        this._win.getDocument().body.removeAttribute('data-keep-enlarged');
        this._win.getDocument().body.classList.remove('sidebar-enable');
        this._win.getDocument().body.classList.remove('vertical-collpsed');
        this._win.getDocument().body.removeAttribute('data-layout-scrollable');
        break;
      case "dark":
        this._win.getDocument().body.setAttribute('data-sidebar', 'dark');
        this._win.getDocument().body.removeAttribute('data-topbar');
        this._win.getDocument().body.removeAttribute('data-layout-size');
        this._win.getDocument().body.removeAttribute('data-keep-enlarged');
        this._win.getDocument().body.removeAttribute('data-sidebar-size');
        this._win.getDocument().body.classList.remove('sidebar-enable');
        this._win.getDocument().body.classList.remove('vertical-collpsed');
        this._win.getDocument().body.removeAttribute('data-layout-scrollable');
        break;
      case "icon":
        this._win.getDocument().body.classList.add('vertical-collpsed');
        this._win.getDocument().body.setAttribute('data-sidebar', 'dark');
        this._win.getDocument().body.removeAttribute('data-layout-size');
        this._win.getDocument().body.setAttribute('data-keep-enlarged',"true");
        this._win.getDocument().body.removeAttribute('data-topbar');
        this._win.getDocument().body.removeAttribute('data-layout-scrollable');
        break;
      case "colored":
        this._win.getDocument().body.classList.remove('sidebar-enable');
        this._win.getDocument().body.classList.remove('vertical-collpsed');
        this._win.getDocument().body.setAttribute('data-sidebar', 'colored');
        this._win.getDocument().body.removeAttribute('data-layout-size');
        this._win.getDocument().body.removeAttribute('data-keep-enlarged');
        this._win.getDocument().body.removeAttribute('data-topbar');
        this._win.getDocument().body.removeAttribute('data-layout-scrollable');
        this._win.getDocument().body.removeAttribute('data-sidebar-size');
        break;
      default:
        this._win.getDocument().body.setAttribute('data-sidebar', 'dark');
        break;
    }
  }

   /**
   * On mobile toggle button clicked
   */
    onToggleMobileMenu() {
      this.isCondensed = !this.isCondensed;
      this._win.getDocument().body.classList.toggle('sidebar-enable');
      this._win.getDocument().body.classList.toggle('vertical-collpsed');
    }

}
