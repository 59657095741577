import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { interval, Observable } from "rxjs";
import { map } from "rxjs/internal/operators";
import { OwlOptions } from "ngx-owl-carousel-o";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../../core/services/language.service";
import { SsrCookieService } from "ngx-cookie-service-ssr";

import { EventService } from "src/app/core/services/event.service";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { WindowService } from "src/app/core/services/window.service";
import Swal from "sweetalert2";
import { ApiService } from "src/app/core/services/api.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  cookieValue: string;
  company: any;
  currentLanguage: string = "en";
  enableEnglish: boolean = true;
  isLanguageDropdownOpen: boolean = false;
  textStyle: string;
  userProfile: any;
  error: any;
  profilePic: any;
  notificationCount: number = 0;
  notificationIds: number[] = [];


  constructor(
    private _win: WindowService,
    private router: Router,
    private translate: TranslateService,
    public languageService: LanguageService,
    private cookieService: SsrCookieService,
    private event: EventService,
    private helper: JwtHelperService,
    private apiService: ApiService,
    private translationService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  lang;
  userType: any;
  userData = null;
  menuItems = [];
  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "عربى", flag: "assets/images/flags/sa.jpg", lang: "ar" },
  ];

  currentSection = "home";
  navPage = "";

  loginSession: boolean = false;

  navigate(page) {
    console.log("oooo");

    this.router.navigate([page]);
  }

  getLanguageFlag(lang: string): string {
    // Add logic to get the corresponding flag image URL based on the language
    // Example: '/assets/images/svg/en.svg'
    return `/assets/images/svg/${lang}.svg`;
  }
  logonavigate() {
    if (this.userData === null) {
      this.router.navigate(["/"]);
    } else if (this.userData.userType.typeName === "supplier") {
      this.router.navigate(["/dashboard"]);
    } else if (this.userData.userType.typeName === "customer") {
      this.router.navigate(["/"]);
    }
  }
  status4logonavigate() {
    this.router.navigate(["/account-recovery"]);
  }
  listNavigate() {
    this.getallProducts();
  }

  ngOnInit() {
    switch (this.router.url.split("/")[1]) {
      case "profile":
        this.navPage = "profile";
        break;
    }
    switch (this.router.url.split("/")[1]) {
      case "favourites":
        this.loginSession = true;
        break;
      case "inquiries":
        this.loginSession = true;
        break;
      case "add-listing":
        this.loginSession = true;
        break;
      case "all-listing":
        this.loginSession = true;
        break;
      case "profile":
        this.loginSession = true;
        break;
      case "":
        this.loginSession = true;
        break;
      case "my-rentals":
        this.loginSession = true;
        break;
      case "my-contracts":
        this.loginSession = true;
        break;
      case "notifications":
        this.loginSession = true;
        break;
      case "default":
        this.loginSession = true;
        break;
    }

    this.cookieValue = this.cookieService.get("lang");
    if (this.cookieService.get("currentUser") !== "") {
      this.userData = JSON.parse(this.cookieService.get("currentUser"));
      console.log(this.userData);
    }
    if (this.cookieService.get("userType") !== "") {
      this.userType = this.cookieService.get("userType");
    }
    console.log("userType", this.userType);

    this.lang = this.listLang.filter((x) => x.lang !== this.cookieValue);
    if (this.lang.length === 0) {
      this.lang = this.listLang.filter((x) => x.lang !== "en");
    }
    this.getUserProfile();
  }


  getUserProfile() {
    this.apiService.get(`/dayim/me?type=${this.userType}`).subscribe(
        (res) => {
            if (res && res["status"] == "success") {
                console.log(res["data"]);

                this.userProfile = res["data"];
                this.profilePic =  this.userProfile.userDetails.profile;
                this.notificationIds = this.userProfile.userDetails

                const count = this.userProfile.notificationCount;

                if (count > 0) {
                    this.notificationCount = 5;
                }


                // Check if userDetalise exists before accessing notifications
                if (this.userProfile && this.userProfile.userDetails) {
        

                    // Map and store the notification IDs
                    this.notificationIds = this.userProfile.userDetails.notifications.map(notification => notification.id);
                    console.log(this.notificationIds);
                } else {
                  console.log("enter to elsee");
                  
                    this.notificationIds = [];
                }
            }
        },
        (error) => {
            this.error = error ? error : "";
        }
    );
}


  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = this._win.getDocument().getElementById("navbar");
    if (
      this._win.getDocument().body.scrollTop >= 50 ||
      this._win.getDocument().documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    this._win
      .getDocument()
      .getElementById("topnav-menu-content")
      .classList.toggle("show");
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  setLanguage(lang: string) {
    this.languageService.setLanguage(lang);
    this.lang = this.listLang.filter((x) => x.lang !== lang);
    this.cookieValue = lang;
  }
  onLayoutChange(route) {
    this.router.navigate([route]);
  }

  logout() {
    // Display a confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log me out!",
    }).then((result) => {
      // If the user clicks "Yes"
      if (result.isConfirmed) {
        // Clear localStorage, delete cookies, and navigate to the home page
        this.userData = null;
        this.router.navigate(["/"]);
        // this.event.broadcast('logout');
        this.cookieService.deleteAll("/");
        // window.location.reload();
      }
    });
  }

  getallProducts() {
    this.apiService.get("/supplier/all-product").subscribe(
      (res) => {
        console.log(res);
        this.company = res["company"];

        if (this.company.length === 1) {
          // If comapany array has at least one item, navigate to /add listing
          this.router.navigate(["/add-listing"]);
        } else {
          this.router.navigate(["/company-credibility"]);
        }
      },
      (error) => {
        console.log("Error Response:", error);
      }
    );
  }

  onNotificationClick(event: Event) {
    event.preventDefault(); // Prevent the default action of the anchor tag

    const payload = {
      ids: this.notificationIds
  };

  // Make the API call
  this.apiService.put('/dayim/notification/read', payload).subscribe(
      (res) => {
        console.log('Notification API called successfully', res);
        // Handle successful response here
      },
      (error) => {
        console.error('Error calling notification API', error);
        // Handle error response here
      }
    );
  }

}
