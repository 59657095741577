import { Component , OnInit, ViewEncapsulation} from '@angular/core';
// import { Subscription } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit  {

  //keep refs to subscriptions to be able to unsubscribe later
  // private popupOpenSubscription: Subscription;

  constructor(  ) { }
  ngOnInit() { }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    // this.popupOpenSubscription.unsubscribe();
  }
}
