import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { environment } from '../environments/environment';

import {  NgbAccordionModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import {NgxImageCompressService} from "ngx-image-compress";
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { initializeApp } from "firebase/app";
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { SuccessInfoPageComponent } from './shared/ui/info-record/success-info-page.component';
import { IntroPageComponent } from './shared/ui/intro-page/intro-page.component';
import { I18nModule } from './i18n/i18n.module';
import { WindowService } from 'src/app/core/services/window.service';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
initializeApp(environment.firebase);


export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.appURL,
    expiryDays: 30
  },
  palette: {
    popup: {
      background: '#fff'
    }
  },
  type: 'info',
  elements:{
    dismiss: '<span aria-label="dismiss cookie message" tabindex="0" class="cc-btn cc-dismiss rounded-3 fw-semibold btn-dayim">{{dismiss}}</span>',
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message fs-6">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank" rel="noopener">{{tosLink}}</a>
    </span>
    `,
  },
  content:{
    message: 'By using our site, you acknowledge that you have read and understand our ',
    
    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: environment.appURL+'/cookies-policy',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: environment.appURL+'/privacy-policy',

    tosLink: 'Terms of Service',
    tosHref: environment.appURL+'/terms-condition'
  }

};

@NgModule({
  declarations: [
    AppComponent,
    SuccessInfoPageComponent,
    IntroPageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({ // for JwtHelperService
      config: {
        tokenGetter: () => {
          return '';
        }
      }
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    I18nModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    NgxExtendedPdfViewerModule

    
  ],
  exports: [SuccessInfoPageComponent],
  providers: [
    JwtHelperService,
    NgxImageCompressService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
 
})

export class AppModule { }
