import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';

import {
  LAYOUT_VERTICAL, LAYOUT_HORIZONTAL, LAYOUT_WIDTH
} from './layouts.model';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { WindowService } from '../core/services/window.service';
import { isPlatformBrowser } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit, AfterViewInit {

  // layout related config
  layoutType: string;
  layoutwidth: string;
  topbar: string;
  currentRoute:any;
  isBrowser:Boolean;

  constructor(private _win: WindowService,private router: Router, private helper: JwtHelperService, @Inject(PLATFORM_ID) platformId: Object,private cookieService: SsrCookieService) { 
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    // default settings
    this.layoutType = LAYOUT_VERTICAL;
    this.layoutwidth = LAYOUT_WIDTH;
    this.layoutChange()

    // detect router changes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.layoutChange()
      }
    });

    this.LayoutWidth(this.layoutwidth);
  }

  ngAfterViewInit() {
  }

  LayoutWidth(width: string) {
    switch (width) {
      case "fluid":
        this._win.getDocument().body.setAttribute("data-layout-size", "fluid");
        this._win.getDocument().body.classList.remove("vertical-collpsed");
        this._win.getDocument().body.removeAttribute("data-layout-scrollable");
        break;
      case "boxed":
        this._win.getDocument().body.setAttribute("data-layout-size", "boxed");
        this._win.getDocument().body.classList.add("vertical-collpsed");
        this._win.getDocument().body.removeAttribute("data-layout-scrollable");
        break;
      case "scrollable":
        this._win.getDocument().body.removeAttribute("data-layout-size");
        this._win.getDocument().body.setAttribute("data-layout-scrollable", "true");
        this._win.getDocument().body.setAttribute("data-layout-size", "fluid");
        this._win.getDocument().body.classList.remove("right-bar-enabled", "vertical-collpsed");
      default:
        this._win.getDocument().body.setAttribute("data-layout-size", "fluid");
        break;
    }
  }
  layoutChange() {
    // if(this.isBrowser){
      const accessToken = this.cookieService.get('userAccessToken');
      const userType = this.helper.decodeToken(accessToken)?.userType
      
      this.currentRoute = this.router.url
      if( userType === undefined || this.currentRoute.match('(\/$)') !== null || (userType === 'supplier' && this.currentRoute.match('(\/marketplace)') !== null) ){
        this.layoutType = LAYOUT_HORIZONTAL;
      }
      else{
        this.layoutType = LAYOUT_VERTICAL;
      }
      //console.log("works")
    // }
  }
}
