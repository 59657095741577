<div id="layout-wrapper">
  <app-header></app-header>

  <!-- <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>

  <app-sidebar></app-sidebar> -->

  <div >
    <div class="page-content">
      <!-- content -->
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <!-- <app-footer></app-footer> -->
  </div>
</div>

