<footer class="landing-footer-custom" style="background-color: rgba(0, 0, 0, 0.9)">
  <ng-container *ngIf="!routerLink || routerLink=='about-us'">
    <div class="col-lg-12 col-md-12 d-flex justify-content-center align-items-center footer-header-div">
      <div class="footer-header">{{ 'FOOTER.ONE_STOP_RENTAL' | translate }}</div>
    </div>
    <div class="row footer-center-div">
      <div class="d-flex justify-content-around flex-column flex-md-row p-5">
        <div class="company">
          <div class="main-section mt-4 mb-3">
            <img loading="lazy" src="/assets/images/landing_page/company-logo.png" alt="" />
          </div>
          <div>
            <div class="sub-section">{{ 'FOOTER.DAYIM_RENTAL_EQUIPMENT_IS' | translate }}</div>
            <div class="sub-section">{{ 'FOOTER.TO_PROVIDE_HIGH_QUALITY' | translate }}</div>
            <div class="sub-section">{{ 'FOOTER.AND_INTERNATIONAL_STANDARDS' | translate }}</div>
          </div>
        </div>
        <div class="links">
          <div class="main-section mt-3">{{ 'FOOTER.SITEMAP' | translate }}</div>
          <div style="cursor: pointer;" class="sub-section" [routerLink]="['/about-us']">{{ 'FOOTER.ABOUT_US' |
            translate }}</div>
          <div style="cursor: pointer;" class="sub-section" [routerLink]="['/contact-us']">{{ 'FOOTER.CONTACT_US' |
            translate }}</div>
          <div style="cursor: pointer;" class="sub-section" [routerLink]="['/all-listing']">{{ 'FOOTER.ALL_LISTINGS' |
            translate }}</div>
        </div>
        <div class="links">
          <div class="main-section mt-3">{{ 'FOOTER.SOCIAL' | translate }}</div>
          <div class="sub-section d-flex">
            <div class="fs-4">
              <img loading="lazy" src="/assets/images/landing_page/icons8-facebook.svg" alt="" />
            </div>
            <div class="fs-4 ml-3">
              <img loading="lazy" src="/assets/images/landing_page/icons8-instagram.svg" alt="" />
            </div>
            <div class="fs-4 ml-3">
              <img loading="lazy" src="/assets/images/landing_page/icons8-twitter.svg" alt="" />
            </div>

            <div class="fs-4 ml-3">
              <img loading="lazy" src="/assets/images/landing_page/icons8-linkedin.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="row footer-bottom-div">
    <div class="d-flex pb-4 pb-md-0">
      <div class="footer-content flex-grow-1 mx-5">
        2023 © Dayim Holdings.
      </div>
      <div class="footer-content mx-5">
        <a href="https://kpi.co/" target="_blank" style="text-decoration: none; color: inherit;">
          Designed & Developed by KPI
        </a>
      </div>
    </div>
  </div>
</footer>